<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocCstIcms.table"
      :enabledActions="false"
      :nameToExport="'Totais por CST/CSOSN'"
    >
      <template v-slot:column>
        <DxColumn data-field="csticms" alignment="right" caption="CST/CSOSN" />
        <DxColumn
          data-field="vprod"
          data-type="number"
          caption="Produtos"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vdesc"
          data-type="number"
          caption="- Descontos"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vicmsdeson"
          data-type="number"
          caption="- ICMS Deson."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vicmsst"
          data-type="number"
          caption="+ ICMS ST"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vfcpst"
          data-type="number"
          caption="+ FCP ST"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vfrete"
          data-type="number"
          caption="+ Frete"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vseg"
          data-type="number"
          caption="+ Seguros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="voutro"
          data-type="number"
          caption="+ Outros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vipi"
          data-type="number"
          caption="+ IPI"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="total"
          data-type="number"
          caption="= Total"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>

      <template v-slot:footer>
        <DxExport :enabled="true" />
        <DxSummary>
           <DxTotalItem
            column="csticms"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
          <DxTotalItem
            column="vprod"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vdesc"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vicmsdeson"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vicmsst"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vfcpst"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vfrete"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vseg"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="voutro"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vipi"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="total"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>